import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import { lock } from '../../../api/erc'

const LockupListPage = ({ intl, pagination, handleChangePage }) => (
  <>
    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        {pagination.data.length === 0 ? (
          <>{intl.formatMessage({ id: 'Lock Up does not exist.' })}</>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-rounded table-striped border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Lock Up Amount' })}
                    </th>
                    <th scope="col">{intl.formatMessage({ id: 'Period' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'State' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Start date' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'End date' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <th>{item.id}</th>
                        <th>{item.stringAmount} MONTE</th>
                        <th>
                          {item.isLock === '0' ? (
                            <></>
                          ) : (
                            <>
                              {item.term} {intl.formatMessage({ id: 'Day' })}
                            </>
                          )}
                        </th>
                        <th>
                          {item.isLock === '0' ? (
                            <>
                              <span className="badge badge-success">
                                {intl.formatMessage({
                                  id: 'Unlock the Lock up'
                                })}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="badge badge-danger">
                                {intl.formatMessage({ id: 'Lock up' })}
                              </span>
                            </>
                          )}
                        </th>
                        <th>
                          {item.createdAt
                            ? moment(item.createdAt).format('YYYY-MM-DD')
                            : ''}
                        </th>
                        <th>
                          {item.expiredAt
                            ? moment(item.expiredAt).format('YYYY-MM-DD')
                            : ''}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  </>
)

const LockupListWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber
      }
      const { data: response } = await lock(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.lockTotalCount,
          data: response.data.lockList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // init data
  const initData = async () => {
    // set table
    try {
      const params = {
        page: pagination.activePage
      }
      const { data: response } = await lock(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.lockTotalCount,
          data: response.data.lockList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Lockup list' })}
      </PageTitle>

      <LockupListPage
        intl={intl}
        pagination={pagination}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export { LockupListWrapper }
