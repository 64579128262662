import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { Theme, toast } from 'react-toastify'
import { sendSupportEmail } from '../../../api/customer'
import { sendEmail } from '../../../api/temp'
import { PageTitle } from '../../../_metronic/layout/core'
import { useThemeMode } from '../../../_metronic/partials'

const EmailPage = ({
  intl,
  form,
  isDisabled,
  handleUpdateForm,
  handleSendEmail
}) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div>
          <div className="form-group mt-5">
            <label htmlFor="title">{intl.formatMessage({ id: 'Title' })}</label>
            <input
              type="text"
              className="form-control mt-3"
              id="title"
              value={form.title}
              onChange={(e) => handleUpdateForm('title', e)}
            />
          </div>
        </div>

        <div>
          <div className="form-group mt-5">
            <label htmlFor="username">
              {intl.formatMessage({ id: 'EMAIL.USERNAME' })}
            </label>
            <input
              type="text"
              className="form-control mt-3"
              id="username"
              value={form.username}
              onChange={(e) => handleUpdateForm('username', e)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group mt-5">
              <label htmlFor="phone">
                {intl.formatMessage({ id: 'Phone' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="phone"
                value={form.phone}
                onChange={(e) => handleUpdateForm('phone', e)}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group mt-5">
              <label htmlFor="email">
                {intl.formatMessage({ id: 'Email' })}
              </label>
              <input
                type="email"
                className="form-control mt-3"
                id="email"
                value={form.email}
                onChange={(e) => handleUpdateForm('email', e)}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="form-group mt-5">
            <label htmlFor="content">
              {intl.formatMessage({ id: 'EMAIL.CONTENT' })}
            </label>
            <textarea
              className="form-control mt-3"
              id="content"
              value={form.content}
              rows={8}
              onChange={(e) => handleUpdateForm('content', e)}
            />
          </div>
        </div>

        <div className="mt-3">
          ※{' '}
          {intl.formatMessage({
            id: 'All items are required, so please fill them out without any spaces.'
          })}
        </div>

        <div className="c-tar">
          <button
            className="btn btn-light-success"
            onClick={handleSendEmail}
            disabled={isDisabled}
          >
            <i className="bi bi-send fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Contact Us' })}
          </button>
        </div>
      </div>
    </div>
  </>
)

const EmailWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const { mode } = useThemeMode()

  // state
  const [isDisabled, setIsDisabled] = useState(false)

  const [form, setForm] = useState({
    title: '',
    username: '',
    phone: '',
    email: '',
    content: ''
  })

  // handler
  const initForm = () => {
    setForm({
      title: '',
      username: '',
      phone: '',
      email: '',
      content: ''
    })
  }

  // handler
  const handleUpdateForm = (key, val) => {
    if (key === 'title') {
      setForm({ ...form, title: val.target.value })
    } else if (key === 'username') {
      setForm({ ...form, username: val.target.value })
    } else if (key === 'phone') {
      setForm({ ...form, phone: val.target.value })
    } else if (key === 'email') {
      setForm({ ...form, email: val.target.value })
    } else if (key === 'content') {
      setForm({ ...form, content: val.target.value })
    }
  }

  // handler
  const handleSendEmail = async () => {
    setIsDisabled(true)
    try {
      const params = {
        title: form.title,
        name: form.username,
        phoneNumber: form.phone,
        email: form.email,
        message: form.content
      }
      const { data: response } = await sendSupportEmail(params)
      if (response.statusCode === 200) {
        alert(intl.formatMessage({ id: response.message }))
        setIsDisabled(false)
        initForm()
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Contact Email' })}
      </PageTitle>

      <EmailPage
        intl={intl}
        form={form}
        isDisabled={isDisabled}
        handleUpdateForm={handleUpdateForm}
        handleSendEmail={handleSendEmail}
      />
    </>
  )
}

export { EmailWrapper }
