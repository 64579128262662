import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootURL = '/api/wallet/'

export const getBalance = () => {
  const url = `${rootURL}balance`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getAddress = () => {
  const url = `${rootURL}address`
  const response = axios.get<CommonResponse>(url)
  return response
}
