import { FC, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Theme, toast } from 'react-toastify'
import { PageTitle } from '../../../_metronic/layout/core'
import { useThemeMode } from '../../../_metronic/partials'
import BigNumber from 'bignumber.js'
import { getAddress, getTokenPrice, marketData } from '../../../api/krw'
import { Modal } from 'react-bootstrap'
import ApexCharts from 'apexcharts'

const CoinPurchasePage = ({
  intl,
  tab,
  buyKRW,
  bank,
  showModal,
  chartRef,
  handleClickTab,
  handleChangeBuyKRW,
  handleOpenModal,
  handleCloseModal
}) => (
  <>
    <div className="row">
      <div className="col">
        <div className="card card-custom">
          <div className="card-body">
            <div className="c-tac">
              <img
                src="/media/custom/sample.png"
                alt="sample"
                style={{
                  maxWidth: '300px',
                  maxHeight: '300px'
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card card-custom">
          <div className="card-body">
            <div className="mb-5">
              <div className="row">
                <div
                  className={`btn col c-tac c-cp ml-2 mr-2 ${
                    tab === 1 ? 'btn-secondary' : 'btn-secondary'
                  }`}
                  onClick={() => handleClickTab(1)}
                >
                  {intl.formatMessage({ id: 'Korean WON' })} (KRW)
                </div>
              </div>
            </div>

            {tab === 1 ? (
              <>
                <div>
                  <div className="form-group mt-5">
                    <label htmlFor="buyKRWAmount">
                      {intl.formatMessage({ id: 'Payment quantity' })} (KRW)
                    </label>
                    <input
                      type="text"
                      className="form-control mt-3"
                      id="buyKRWAmount"
                      value={buyKRW.amount}
                      onChange={(e) => handleChangeBuyKRW(e)}
                    />
                  </div>
                </div>

                <div>
                  <div className="form-group mt-5">
                    <label htmlFor="buyKRWPrice">
                      {intl.formatMessage({ id: 'Token price' })}
                    </label>
                    <input
                      type="text"
                      className="form-control mt-3"
                      id="buyKRWPrice"
                      value={`1 MONTE ≈ ${buyKRW.price} KRW`}
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <div className="form-group mt-5">
                    <label htmlFor="buyKRWToken">
                      {intl.formatMessage({ id: 'Purchase quantity' })} (MONTE)
                    </label>
                    <input
                      type="text"
                      className="form-control mt-3"
                      id="buyKRWToken"
                      value={buyKRW.token}
                      disabled
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-success mt-5 c-w100p"
                    onClick={handleOpenModal}
                  >
                    <i className="bi bi-cart fs-4 me-2"></i>
                    {intl.formatMessage({ id: 'Buying' })}
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            {tab === 2 ? <></> : <></>}
          </div>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body">
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '800px'
          }}
        >
          <div
            ref={chartRef}
            id="kt_charts_widget_1_chart"
            style={{ height: '350px' }}
          />
        </div>
      </div>
    </div>

    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Buying' })}
      </h1>
      <div>
        <div className="form-group mt-5">
          <label htmlFor="password">{intl.formatMessage({ id: 'Bank' })}</label>
          <input
            type="text"
            className="form-control mt-3"
            id="bank"
            disabled
            value={bank.bankType}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="password">
            {intl.formatMessage({ id: 'bank account number' })}
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="password"
            disabled
            value={bank.bankAccount}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="password">
            {intl.formatMessage({ id: 'Deposited stock' })}
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="password"
            disabled
            value={bank.depositName}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="password">
            {intl.formatMessage({ id: 'Amount to be deposited' })} (KRW)
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="password"
            disabled
            value={buyKRW.amount}
          />
        </div>
        <div className="mt-5">
          *{' '}
          {intl.formatMessage({
            id: 'Please check the bank, account number, and depositor above and enter the amount to be deposited.'
          })}
        </div>
        <div className="mt-3">
          *{' '}
          {intl.formatMessage({
            id: 'The administrator will send you a token after confirming your entry.'
          })}
        </div>
      </div>
    </Modal>
  </>
)

const CoinPurchaseWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const { mode } = useThemeMode()

  // state - 기능변경으로 인해 사용하지 않음
  const [tab, setTab] = useState(1)

  // state - 입금 모달
  const [showModal, setShowModal] = useState(false)

  // chart
  const chartRef = useRef(null)

  // 지불수량, 토큰가격, 구매수량
  const initBuyKRW = {
    amount: '',
    price: '',
    token: ''
  }
  const [buyKRW, setBuyKRW] = useState(initBuyKRW)

  const initBank = {
    bankType: '',
    bankAccount: '',
    depositName: '',
    withdrawalFee: ''
  }
  const [bank, setBank] = useState(initBank)

  // handler
  const handleClickTab = (tabNo: number) => {
    setTab(tabNo)
  }

  // handler
  const handleChangeBuyKRW = (val) => {
    const amount = val.target.value
    const amountBN = new BigNumber(amount)
    const priceBN = new BigNumber(buyKRW.price)
    const tokenBN = amountBN.dividedBy(priceBN)
    let token = tokenBN.toFixed(4)
    if (isNaN(Number(token))) {
      token = '0'
    }

    setBuyKRW({ ...buyKRW, amount, token })
  }

  // handler
  const handleOpenModal = () => {
    setShowModal(true)
  }

  // handler
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // init data
  const initData = async () => {
    try {
      const { data: tokenPrice } = await getTokenPrice()
      if (tokenPrice.statusCode === 200) {
        setBuyKRW({
          ...buyKRW,
          price: tokenPrice.data
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }

    try {
      const { data: response2 } = await getAddress()
      if (response2.statusCode === 200) {
        setBank({
          bankType: response2.data.bankType,
          bankAccount: response2.data.bankAccount,
          depositName: response2.data.depositName,
          withdrawalFee: response2.data.withdrawalFee
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // 차트
  const getChartOptions = ({ categories, data }) => {
    return {
      chart: {
        width: 800,
        type: 'area'
      },
      stroke: {
        curve: 'smooth'
      },
      colors: ['#d6512d'],
      series: [
        {
          name: 'USD',
          data: data
        }
      ],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categories
      },
      yaxis: [
        {
          title: {
            text: 'USD',
            style: {
              color: '#d6512d'
            }
          }
        }
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 350
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  }

  const refreshChart = (data) => {
    if (!chartRef.current) {
      return
    }

    // 차트 생성
    const chart = new ApexCharts(chartRef.current, getChartOptions(data))

    // 차트 생성에 문제가 없으면 렌더링
    if (chart) {
      chart.render()
    }

    return chart
  }

  // 차트 API 연동
  const helloChart = async () => {
    const params = {
      type: 'hour1'
    }
    const { data: res } = await marketData(params)
    return res.data
  }

  // lifecycle
  useEffect(() => {
    helloChart().then((data) => {
      console.log('data -> ', data)
      const chart = refreshChart(data)

      return () => {
        // 언마운트 시 차트가 존재할 경우 차트 삭제
        if (chart) {
          chart.destroy()
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode])

  // lifecycle
  useEffect(() => {
    initData()

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Coin purchase' })}
      </PageTitle>

      <CoinPurchasePage
        intl={intl}
        tab={tab}
        buyKRW={buyKRW}
        bank={bank}
        showModal={showModal}
        chartRef={chartRef}
        handleClickTab={handleClickTab}
        handleChangeBuyKRW={handleChangeBuyKRW}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

export { CoinPurchaseWrapper }
