import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootURL = '/api/terms/'

export const service = () => {
  const url = `${rootURL}service`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const privacy = () => {
  const url = `${rootURL}privacy`
  const response = axios.get<CommonResponse>(url)
  return response
}
