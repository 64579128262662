import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import DATE from '../../constants/date'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import { krwDepositAndWithdrawalPagination } from '../../../api/admin'

const KRWDepositAndWithdrawalPage = ({
  intl,
  search,
  pagination,
  handleChangePage,
  handleClickSearch,
  handleChangeSearch
}) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="account">
                {intl.formatMessage({ id: 'Account' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="account"
                value={search.account}
                onChange={(e) => handleChangeSearch('account', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="username">
                {intl.formatMessage({ id: 'Username' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="username"
                value={search.username}
                onChange={(e) => handleChangeSearch('username', e)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="bankType">
                {intl.formatMessage({ id: 'Bank Type' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="bankType"
                value={search.bankType}
                onChange={(e) => handleChangeSearch('bankType', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="bankAccount">
                {intl.formatMessage({ id: 'Bank Account' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="bankAccount"
                value={search.bankAccount}
                onChange={(e) => handleChangeSearch('bankAccount', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="depositor">
                {intl.formatMessage({ id: 'Name of depositor' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="depositor"
                value={search.depositor}
                onChange={(e) => handleChangeSearch('depositor', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="createdAt">
                {intl.formatMessage({ id: 'Date and time of creation' })}
              </label>
              <DatePicker
                className="form-control mt-3"
                dateFormat="yyyy-MM-dd"
                selected={search.createdAt}
                onChange={(date: Date) => handleChangeSearch('createdAt', date)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tar">
          <button className="btn btn-light-primary" onClick={handleClickSearch}>
            <i className="bi bi-search fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Search' })}
          </button>
        </div>
      </div>
    </div>
    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        {pagination.data.length === 0 ? (
          <>
            <div className="c-tac">
              {intl.formatMessage({
                id: 'Data does not exist.'
              })}
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-rounded table-striped border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'Account' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Username' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Bank Type' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Bank Account' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Name of depositor' })}
                    </th>
                    <th scope="col">{intl.formatMessage({ id: 'Amount' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of creation' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <th>{item.id}</th>
                        <th>{item.account}</th>
                        <th>{item.username}</th>
                        <th>{item.bankType}</th>
                        <th>{item.bankAccount}</th>
                        <th>{item.depositor}</th>
                        <th>{item.amount}</th>
                        <th>
                          {item.createdAt
                            ? moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  </>
)

const KRWDepositAndWithdrawalWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // state - search
  const [search, setSearch] = useState({
    account: '',
    username: '',
    bankType: '',
    depositor: '',
    bankAccount: '',
    createdAt: ''
  })

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    const createdAt = search.createdAt
      ? moment(search.createdAt).format(DATE.ONLY_DATE)
      : ''

    // change paging
    setPagination({ ...pagination, activePage: pageNumber })
    // set table
    try {
      const params = {
        page: pageNumber,
        account: search.account,
        username: search.username,
        bankType: search.bankType,
        depositor: search.depositor,
        bankAccount: search.bankAccount,
        createdAt: createdAt
      }
      const { data: response } = await krwDepositAndWithdrawalPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList,
          activePage: pageNumber
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - click search
  const handleClickSearch = async () => {
    const createdAt = search.createdAt
      ? moment(search.createdAt).format(DATE.ONLY_DATE)
      : ''

    // set table
    try {
      const params = {
        page: 1,
        account: search.account,
        username: search.username,
        bankType: search.bankType,
        depositor: search.depositor,
        bankAccount: search.bankAccount,
        createdAt: createdAt
      }
      const { data: response } = await krwDepositAndWithdrawalPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.krwTransactionHistoryTotalCount,
          data: response.data.krwTransactionHistoryList,
          activePage: 1
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - change search
  const handleChangeSearch = (type: string, data: any) => {
    if (type === 'account') {
      setSearch({ ...search, account: data.target.value })
    } else if (type === 'username') {
      setSearch({ ...search, username: data.target.value })
    } else if (type === 'bankType') {
      setSearch({ ...search, bankType: data.target.value })
    } else if (type === 'bankAccount') {
      setSearch({ ...search, bankAccount: data.target.value })
    } else if (type === 'depositor') {
      setSearch({ ...search, depositor: data.target.value })
    } else if (type === 'createdAt') {
      setSearch({ ...search, createdAt: data })
    }
  }

  // init data
  const initData = async () => {
    // set table
    try {
      const createdAt = search.createdAt
        ? moment(search.createdAt).format(DATE.ONLY_DATE)
        : ''

      const params = {
        page: pagination.activePage,
        account: search.account,
        username: search.username,
        bankType: search.bankType,
        depositor: search.depositor,
        bankAccount: search.bankAccount,
        createdAt: createdAt
      }
      const { data: response } = await krwDepositAndWithdrawalPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.krwTransactionHistoryTotalCount,
          data: response.data.krwTransactionHistoryList,
          activePage: pagination.activePage
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'KRW Deposit and Withdrawal History' })}
      </PageTitle>

      <KRWDepositAndWithdrawalPage
        intl={intl}
        search={search}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
      />
    </>
  )
}

export { KRWDepositAndWithdrawalWrapper }
