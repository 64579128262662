import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import DATE from '../../constants/date'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import { coinInternalTransferPagination } from '../../../api/admin'

const InternalTransferPage = ({
  intl,
  search,
  pagination,
  handleChangePage,
  handleClickSearch,
  handleChangeSearch
}) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sample1">
                {intl.formatMessage({ id: 'ID' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="id"
                value={search.id}
                onChange={(e) => handleChangeSearch('id', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="fromUserId">
                {intl.formatMessage({ id: 'From User Id' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="fromUserId"
                value={search.fromUserId}
                onChange={(e) => handleChangeSearch('fromUserId', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="fromAccount">
                {intl.formatMessage({ id: 'From Account' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="fromAccount"
                value={search.fromAccount}
                onChange={(e) => handleChangeSearch('fromAccount', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="fromUsername">
                {intl.formatMessage({ id: 'From Username' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="fromUsername"
                value={search.fromUsername}
                onChange={(e) => handleChangeSearch('fromUsername', e)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="toUserId">
                {intl.formatMessage({ id: 'To User Id' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="toUserId"
                value={search.toUserId}
                onChange={(e) => handleChangeSearch('toUserId', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="toAccount">
                {intl.formatMessage({ id: 'To Account' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="toAccount"
                value={search.toAccount}
                onChange={(e) => handleChangeSearch('toAccount', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="toUsername">
                {intl.formatMessage({ id: 'To Username' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="toUsername"
                value={search.toUsername}
                onChange={(e) => handleChangeSearch('toUsername', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="coinType">
                {intl.formatMessage({ id: 'Coin Type' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="coinType"
                value={search.coinType}
                onChange={(e) => handleChangeSearch('coinType', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="amount">
                {intl.formatMessage({ id: 'Amount' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="amount"
                value={search.Amount}
                onChange={(e) => handleChangeSearch('amount', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="createdAt">
                {intl.formatMessage({ id: 'Date and time of creation' })}
              </label>
              <DatePicker
                className="form-control mt-3"
                dateFormat="yyyy-MM-dd"
                selected={search.createdAt}
                onChange={(date: Date) => handleChangeSearch('createdAt', date)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tar">
          <button className="btn btn-light-primary" onClick={handleClickSearch}>
            <i className="bi bi-search fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Search' })}
          </button>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        <div className="table-responsive">
          <table className="table table-rounded table-striped border gy-7 gs-7">
            <thead>
              <tr>
                <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                <th scope="col">
                  {intl.formatMessage({ id: 'From User Id' })}
                </th>
                <th scope="col">
                  {intl.formatMessage({ id: 'From Account' })}
                </th>
                <th scope="col">
                  {intl.formatMessage({ id: 'From Username' })}
                </th>
                <th scope="col">{intl.formatMessage({ id: 'To User Id' })}</th>
                <th scope="col">{intl.formatMessage({ id: 'To Account' })}</th>
                <th scope="col">{intl.formatMessage({ id: 'To Username' })}</th>
                <th scope="col">{intl.formatMessage({ id: 'Coin Type' })}</th>
                <th scope="col">{intl.formatMessage({ id: 'Amount' })}</th>
                <th scope="col">
                  {intl.formatMessage({ id: 'Date and time of creation' })}
                </th>
              </tr>
            </thead>
            <tbody>
              {pagination.data.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <th>{item.id}</th>
                    <th>{item.fromUserId}</th>
                    <th>{item.fromAccount}</th>
                    <th>{item.fromUsername}</th>
                    <th>{item.toUserId}</th>
                    <th>{item.toAccount}</th>
                    <th>{item.toUsername}</th>
                    <th>{item.coinType}</th>
                    <th>{item.amount}</th>
                    <th>
                      {item.createdAt
                        ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')
                        : ''}
                    </th>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  </>
)

const InternalTransferWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // state - search
  const [search, setSearch] = useState({
    id: '',
    fromUserId: '',
    fromAccount: '',
    fromUsername: '',
    toUserId: '',
    toAccount: '',
    toUsername: '',
    coinType: '',
    amount: '',
    createdAt: ''
  })

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber,
        id: search.id,
        fromUserId: search.fromUserId,
        fromAccount: search.fromAccount,
        fromUsername: search.fromUsername,
        toAccount: search.toAccount,
        toUsername: search.toUsername,
        toUserId: search.toUserId,
        amount: search.amount,
        createdAt: search.createdAt
      }
      const { data: response } = await coinInternalTransferPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.coinExternalTransferTotalCount,
          data: response.data.coinExternalTransferList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - click search
  const handleClickSearch = async () => {
    // set table
    try {
      const params = {
        page: 1,
        id: search.id,
        fromUserId: search.fromUserId,
        fromAccount: search.fromAccount,
        fromUsername: search.fromUsername,
        toAccount: search.toAccount,
        toUsername: search.toUsername,
        toUserId: search.toUserId,
        amount: search.amount,
        createdAt: search.createdAt
      }
      const { data: response } = await coinInternalTransferPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.coinExternalTransferTotalCount,
          data: response.data.coinExternalTransferList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - change search
  const handleChangeSearch = (type: string, data: any) => {
    if (type === 'id') {
      setSearch({ ...search, id: data.target.value })
    } else if (type === 'fromUserId') {
      setSearch({ ...search, fromUserId: data.target.value })
    } else if (type === 'fromAccount') {
      setSearch({ ...search, fromAccount: data.target.value })
    } else if (type === 'fromUsername') {
      setSearch({ ...search, toUserId: data.target.value })
    } else if (type === 'toUserId') {
      setSearch({ ...search, toAccount: data.target.value })
    } else if (type === 'toAccount') {
      setSearch({ ...search, toAccount: data.target.value })
    } else if (type === 'coinType') {
      setSearch({ ...search, coinType: data.target.value })
    } else if (type === 'createdAt') {
      setSearch({ ...search, createdAt: data })
    }
  }

  // init data
  const initData = async () => {
    // set table
    try {
      const createdAt = search.createdAt
        ? moment(search.createdAt).format(DATE.ONLY_DATE)
        : ''

      const params = {
        page: pagination.activePage,
        id: search.id,
        fromUserId: search.fromUserId,
        fromUsername: search.fromUsername,
        toUserId: search.toUserId,
        toAccount: search.toAccount,
        toUsername: search.toUsername,
        coinType: search.coinType,
        amount: search.amount,
        createdAt: createdAt
      }
      const { data: response } = await coinInternalTransferPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.coinExternalTransferTotalCount,
          data: response.data.coinExternalTransferList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Coin Internal Transfer History' })}
      </PageTitle>

      <InternalTransferPage
        intl={intl}
        search={search}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
      />
    </>
  )
}

export { InternalTransferWrapper }
