import { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Theme, toast } from 'react-toastify'
import { getBankAccount, updateBankAccount } from '../../../api/admin'
import { PageTitle } from '../../../_metronic/layout/core'
import { useThemeMode } from '../../../_metronic/partials'

const BankAccountPage = ({
  intl,
  form,
  showModal,
  handleClickUpdate,
  handleUpdateForm,
  handleCloseModal,
  handleOpenModal
}) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div className="form-group">
          <label htmlFor="bankType">
            {intl.formatMessage({ id: 'Bank Type' })}
          </label>
          <input
            type="text"
            className="form-control mt-2"
            id="bankType"
            onChange={(e) => handleUpdateForm('bankType', e)}
            value={form.bankType}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="bankAccount">
            {intl.formatMessage({ id: 'Bank Account' })}
          </label>
          <input
            type="text"
            className="form-control mt-2"
            id="bankAccount"
            onChange={(e) => handleUpdateForm('bankAccount', e)}
            value={form.bankAccount}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="depositName">
            {intl.formatMessage({ id: 'Name of account owner' })}
          </label>
          <input
            type="text"
            className="form-control mt-2"
            id="depositName"
            onChange={(e) => handleUpdateForm('depositName', e)}
            value={form.depositName}
          />
        </div>
        <div className="c-tar">
          <div className="mt-5">
            <button className="btn btn-light-primary" onClick={handleOpenModal}>
              <i className="bi bi-pencil-square fs-4 me-2"></i>
              {intl.formatMessage({ id: 'Change account information' })}
            </button>
          </div>
        </div>
      </div>
    </div>

    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'TEXT.WARNING' })}
      </h1>
      <div>
        {intl.formatMessage({
          id: 'Do you want to change your company account information'
        })}
      </div>
      <div className="modal-button">
        <button
          className="btn btn-light-danger mr-3"
          onClick={handleCloseModal}
        >
          <i className="bi bi-x fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Cancel' })}
        </button>
        <button
          className="btn btn-light-success ml-3"
          onClick={handleClickUpdate}
        >
          <i className="bi bi-check fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Change' })}
        </button>
      </div>
    </Modal>
  </>
)

const BankAccountWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const { mode } = useThemeMode()

  // states
  const [form, setForm] = useState({
    bankType: '',
    bankAccount: '',
    depositName: ''
  })

  const [showModal, setShowModal] = useState(false)

  // handler - update form
  const handleUpdateForm = (key, val) => {
    if (key === 'bankType') {
      setForm({ ...form, bankType: val.target.value })
    } else if (key === 'bankAccount') {
      setForm({ ...form, bankAccount: val.target.value })
    } else if (key === 'depositName') {
      setForm({ ...form, depositName: val.target.value })
    }
  }

  // handler - click update button
  const handleClickUpdate = async () => {
    // close modal
    setShowModal(false)
    // set table
    try {
      const params = {
        bankType: form.bankType,
        bankAccount: form.bankAccount,
        depositName: form.depositName
      }
      const { data: response } = await updateBankAccount(params)
      if (response.statusCode === 200) {
        getBankAccount()
        toast.info(
          intl.formatMessage({
            id: 'Successful change of company account information'
          }),
          {
            theme: mode as Theme
          }
        )
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // init data
  const initData = async () => {
    const { data } = await getBankAccount()
    if (data.statusCode === 200) {
      setForm({
        bankType: data.data.bankType,
        bankAccount: data.data.bankAccount,
        depositName: data.data.depositName
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()
    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Company Bank Account' })}
      </PageTitle>

      <BankAccountPage
        intl={intl}
        form={form}
        showModal={showModal}
        handleClickUpdate={handleClickUpdate}
        handleUpdateForm={handleUpdateForm}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
      />
    </>
  )
}

export { BankAccountWrapper }
