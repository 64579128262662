/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { useAuth } from '../../../../app/modules/auth'
import { AsideMenuItem } from './AsideMenuItem'

/*
  icon (duotune)
  https://preview.keenthemes.com/metronic8/react/docs/docs/icons/duotune

  if you want to use font icon, change aside.menuIcon = 'font'
  - frontend/src/_metronic/layout/core/DefaultLayoutConfig.ts
*/

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: 'Main' })}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to="/main/depositAndWithdrawal/"
        icon="/media/icons/duotune/finance/fin008.svg"
        title={intl.formatMessage({ id: 'Deposit and withdrawal' })}
      />
      <AsideMenuItem
        to="/main/coinPurchase/"
        icon="/media/icons/duotune/finance/fin010.svg"
        title={intl.formatMessage({ id: 'Coin purchase' })}
      />
      <AsideMenuItem
        to="/main/lockupList/"
        icon="/media/icons/duotune/graphs/gra004.svg"
        title={intl.formatMessage({ id: 'Lockup list' })}
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({ id: 'Customer Service' })}
          </span>
        </div>
      </div>
      <AsideMenuItem
        to="/cs/support/"
        icon="/media/icons/duotune/abstract/abs024.svg"
        title={intl.formatMessage({ id: 'Get Support' })}
      />
      <AsideMenuItem
        to="/cs/notice/"
        icon="/media/icons/duotune/abstract/abs001.svg"
        title={intl.formatMessage({ id: 'Notice' })}
      />
      <AsideMenuItem
        to="/cs/faq/"
        icon="/media/icons/duotune/abstract/abs005.svg"
        title={intl.formatMessage({ id: 'Frequently Asked Questions' })}
      />
      <AsideMenuItem
        to="/cs/email/"
        icon="/media/icons/duotune/abstract/abs026.svg"
        title={intl.formatMessage({ id: 'Contact Email' })}
      />

      {currentUser.isAdmin === 1 ? (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                {intl.formatMessage({ id: 'Admin' })}
              </span>
            </div>
          </div>
          <AsideMenuItem
            to="/admin/user/"
            icon="/media/icons/duotune/communication/com005.svg"
            title={intl.formatMessage({ id: 'User' })}
          />

          <AsideMenuItem
            to="/admin/wallet/"
            icon="/media/icons/duotune/communication/com005.svg"
            title={intl.formatMessage({ id: 'Wallet Management' })}
          />

          <AsideMenuItem
            to="/admin/userSignIn/"
            icon="/media/icons/duotune/abstract/abs016.svg"
            title={intl.formatMessage({ id: 'User Sign In History' })}
          />
          <AsideMenuItem
            to="/admin/externalTransfer/"
            icon="/media/icons/duotune/abstract/abs039.svg"
            title={intl.formatMessage({ id: 'Coin External Transfer History' })}
          />
          <AsideMenuItem
            to="/admin/KRWDepositAndWithdrawal/"
            icon="/media/icons/duotune/abstract/abs042.svg"
            title={intl.formatMessage({
              id: 'KRW Deposit and Withdrawal History'
            })}
          />
          <AsideMenuItem
            to="/admin/lockup/"
            icon="/media/icons/duotune/abstract/abs026.svg"
            title={intl.formatMessage({ id: 'Lock Up History' })}
          />
          <AsideMenuItem
            to="/admin/notice/"
            icon="/media/icons/duotune/abstract/abs047.svg"
            title={intl.formatMessage({ id: 'Notice' })}
          />
          <AsideMenuItem
            to="/admin/faq/"
            icon="/media/icons/duotune/abstract/abs045.svg"
            title={intl.formatMessage({ id: 'Frequently Asked Questions' })}
          />
          <AsideMenuItem
            to="/admin/bankAccount/"
            icon="/media/icons/duotune/finance/fin001.svg"
            title={intl.formatMessage({ id: 'Company Bank Account' })}
          />
        </>
      ) : (
        <></>
      )}

      {/* Develop */}
      {process.env.REACT_APP_NAME === 'development' ? (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Develop Sample
              </span>
            </div>
          </div>
          <AsideMenuItem
            to="/develop/template/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.TEMPLATE_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/axios/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.AXIOS_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/button/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.BUTTON_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/reactState/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({
              id: 'MENU.DEVELOP.REACT_STATE_SAMPLE'
            })}
          />
          <AsideMenuItem
            to="/develop/reduxState/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({
              id: 'MENU.DEVELOP.REDUX_STATE_SAMPLE'
            })}
          />
          <AsideMenuItem
            to="/develop/toast/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.TOAST_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/lottie/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.LOTTIE_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/modal/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.MODAL_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/bignumber/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.BIGNUMBER_SAMPLE' })}
          />
          <AsideMenuItem
            to="/develop/pagination/"
            icon="/media/icons/duotune/general/gen029.svg"
            title={intl.formatMessage({ id: 'MENU.DEVELOP.PAGINATION_SAMPLE' })}
          />

          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                Develop
              </span>
            </div>
          </div>
          <AsideMenuItem
            to="/user/management/"
            icon="/media/icons/duotune/communication/com006.svg"
            title={intl.formatMessage({ id: 'MENU.USER.USER_MANAGEMENT' })}
          />
          <AsideMenuItem
            to="/query/execute/"
            icon="/media/icons/duotune/general/gen017.svg"
            title={intl.formatMessage({ id: 'MENU.QUERY.QUERY_EXECUTE' })}
          />
          <AsideMenuItem
            to="/query/history/"
            icon="/media/icons/duotune/general/gen013.svg"
            title={intl.formatMessage({ id: 'MENU.QUERY.QUERY_HISTORY' })}
          />
        </>
      ) : (
        <></>
      )}
    </>
  )
}
