import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootURL = '/api/customer/'

export const getQna = () => {
  const url = `${rootURL}qna`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getSupport = () => {
  const url = `${rootURL}support`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getNotice = (params: object) => {
  const url = `${rootURL}notice`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

export const getNoticeDetail = (params: object) => {
  const url = `${rootURL}notice-detail`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

export const sendSupportEmail = (params: object) => {
  const url = `${rootURL}support-email`
  const response = axios.post<CommonResponse>(url, params)
  return response
}
