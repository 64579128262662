import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootURL = '/api/user/'

export const account = () => {
  const url = `${rootURL}account`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const changePassword = (params: object) => {
  const url = `${rootURL}changePassword`
  const response = axios.put<CommonResponse>(url, params)
  return response
}

export const signUp = (params: object) => {
  const url = `${rootURL}sign-up`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

export const signHistory = (params: object) => {
  const url = `${rootURL}sign-history`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

export const activeUser = (params: object) => {
  const url = `${rootURL}activeUser`
  const response = axios.put<CommonResponse>(url, params)
  return response
}

export const resetPasswordMail = (params: object) => {
  const url = `${rootURL}resetPasswordMail`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

export const resetPassword = (params: object) => {
  const url = `${rootURL}resetPassword`
  const response = axios.put<CommonResponse>(url, params)
  return response
}

export const sendSMS = (params: object) => {
  const url = `${rootURL}send-sms`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

export const verifySMS = (params: object) => {
  const url = `${rootURL}verify-sms`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

export const signExit = (params: object) => {
  const url = `${rootURL}sign-exit`
  const response = axios.delete<CommonResponse>(url, params)
  return response
}

// ANCHOR QR code API
export const qrCodeAPI = () => {
  const url = `${rootURL}qr-code`
  const response = axios.get<CommonResponse>(url)
  return response
}

// ANCHOR active OTP API
export const activeOTPAPI = (params: object) => {
  const url = `${rootURL}active-otp`
  const response = axios.put<CommonResponse>(url, params)
  return response
}
