import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootUrl = '/api/krw/'

export const getAddress = () => {
  const url = `${rootUrl}address`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getTokenPrice = () => {
  const url = `${rootUrl}token-price`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const deposit = (params: object) => {
  const url = `${rootUrl}deposit`
  const response = axios.post<CommonResponse>(url, params)
  return response
}

export const marketData = (params: object) => {
  const url = `${rootUrl}market-data`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}
