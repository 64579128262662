import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { QueryExecuteWrapper } from '../pages/query/QueryExecuteWrapper'
import { QueryHistoryWrapper } from '../pages/query/QueryHistoryWrapper'
import { DevelopAxiosWrapper } from '../pages/develop/DevelopAxiosWrapper'
import { DevelopButtonWrapper } from '../pages/develop/DevelopButtonWrapper'
import { DevelopReactStateWrapper } from '../pages/develop/DevelopReactStateWrapper'
import { DevelopReduxStateWrapper } from '../pages/develop/DevelopReduxStateWrapper'
import { AccountSettingsWrapper } from '../pages/account-settings/AccountSettingsWrapper'
import { DevelopToastWrapper } from '../pages/develop/DevelopToastWrapper'
import { DevelopTemplateWrapper } from '../pages/develop/DevelopTemplateWrapper'
import { DevelopLottieWrapper } from '../pages/develop/DevelopLottieWrapper'
import { DevelopModalWrapper } from '../pages/develop/DevelopModalWrapper'
import { DevelopBignumberWrapper } from '../pages/develop/DevelopBigNumberWrapper'
import { DevelopPaginationWrapper } from '../pages/develop/DevelopPaginationWrapper'
import { LockupListWrapper } from '../pages/main/LockupListWrapper'
import { SupportWrapper } from '../pages/cs/SupportWrapper'
import { NoticeWrapper } from '../pages/cs/NoticeWrapper'
import { FAQWrapper } from '../pages/cs/FAQWrapper'
import { EmailWrapper } from '../pages/cs/EmailWrapper'
import { CoinPurchaseWrapper } from '../pages/main/CoinPurchaseWrapper'
import { DepositAndWithdrawalWrapper } from '../pages/main/DepositAndWithdrawalWrapper'
import { UserManagementWrapper } from '../pages/admin/UserManagementWrapper'
import { UserSignInWrapper } from '../pages/admin/UserSignInWrapper'
import { ExternalTransferWrapper } from '../pages/admin/ExternalTransferWrapper'
import { InternalTransferWrapper } from '../pages/admin/InternalTransferWrapper'
import { KRWDepositAndWithdrawalWrapper } from '../pages/admin/KRWDepositAndWithdrawalWrapper'
import { LockUpWrapper } from '../pages/admin/LockUpWrapper'
import { BankAccountWrapper } from '../pages/admin/BankAccountWrapper'
import { FAQManagementWrapper } from '../pages/admin/FAQManagementWrapper'
import { NoticeAdminWrapper } from '../pages/admin/NoticeAdminWrapper'
import { WalletManagementWrapper } from '../pages/admin/WalletManagementWrapper'

/*
  react-route-dom v6
  https://reactrouter.com/docs/en/v6/getting-started/overview

  example
  dashboard  (x)
  dashboard/ (o)
*/
const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/cs/notice/" />} />

        {/* Pages - Account Settings */}
        <Route path="/account/settings/" element={<AccountSettingsWrapper />} />

        {/* Pages - Dashboard */}
        {/* <Route path="/dashboard/" element={<DashboardWrapper />} /> */}

        {/* Pages - Main */}
        <Route
          path="/main/depositAndWithdrawal/"
          element={<DepositAndWithdrawalWrapper />}
        />
        <Route path="/main/coinPurchase/" element={<CoinPurchaseWrapper />} />
        <Route path="/main/lockupList/" element={<LockupListWrapper />} />

        {/* Pages - Customer Service */}
        <Route path="/cs/support/" element={<SupportWrapper />} />
        <Route path="/cs/notice/" element={<NoticeWrapper />} />
        <Route path="/cs/faq/" element={<FAQWrapper />} />
        <Route path="/cs/email/" element={<EmailWrapper />} />

        {/* Pages - Admin */}
        <Route path="/admin/user/" element={<UserManagementWrapper />} />
        <Route path="/admin/wallet/" element={<WalletManagementWrapper />} />

        <Route path="/admin/userSignIn/" element={<UserSignInWrapper />} />
        <Route
          path="/admin/externalTransfer/"
          element={<ExternalTransferWrapper />}
        />
        <Route
          path="/admin/internalTransfer/"
          element={<InternalTransferWrapper />}
        />
        <Route
          path="/admin/KRWDepositAndWithdrawal/"
          element={<KRWDepositAndWithdrawalWrapper />}
        />
        <Route path="/admin/lockup/" element={<LockUpWrapper />} />
        <Route path="/admin/faq/" element={<FAQManagementWrapper />} />
        <Route path="/admin/notice/" element={<NoticeAdminWrapper />} />
        <Route path="/admin/bankAccount/" element={<BankAccountWrapper />} />

        {/* Pages - Develop */}
        <Route path="/develop/template/" element={<DevelopTemplateWrapper />} />
        <Route path="/develop/axios/" element={<DevelopAxiosWrapper />} />
        <Route path="/develop/button/" element={<DevelopButtonWrapper />} />
        <Route
          path="/develop/reactState/"
          element={<DevelopReactStateWrapper />}
        />
        <Route
          path="/develop/reduxState/"
          element={<DevelopReduxStateWrapper />}
        />
        <Route path="/develop/toast/" element={<DevelopToastWrapper />} />
        <Route path="/develop/lottie/" element={<DevelopLottieWrapper />} />

        <Route path="/develop/modal/" element={<DevelopModalWrapper />} />
        <Route
          path="/develop/bignumber/"
          element={<DevelopBignumberWrapper />}
        />
        <Route
          path="/develop/pagination/"
          element={<DevelopPaginationWrapper />}
        />

        {/* Pages - Query */}
        <Route path="/query/execute/" element={<QueryExecuteWrapper />} />
        <Route path="/query/history/" element={<QueryHistoryWrapper />} />

        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
