/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Theme } from 'react-toastify'
import { toast, ToastContainer } from 'react-toastify'
import { useIntl } from 'react-intl'
import { useThemeMode } from '../../../../_metronic/partials'
import { resetPassword } from '../../../../api/user'

export function ForgotPasswordComplete() {
  // hooks
  const navigate = useNavigate()
  const intl = useIntl()
  const { mode } = useThemeMode()

  // parameter
  const search = useLocation().search
  const activeCode = new URLSearchParams(search).get('activeCode')

  if (activeCode === null) {
    // redirect
    window.location.href = '/'
  }

  // handler
  const handleClickSignIn = () => {
    navigate('/auth/signIn')
  }

  // init data
  const initData = async () => {
    try {
      const params = {
        activeCode
      }
      const { data: response } = await resetPassword(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()
  }, [])

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={() => {}}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">
          {intl.formatMessage({ id: 'Password change complete' })}
        </h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className="c-tac" style={{ fontSize: '15px', marginBottom: '50px' }}>
        {intl.formatMessage({ id: 'Password change is complete.' })}
      </div>

      <div className="c-tac mt-5">
        <button className="btn btn-light-success" onClick={handleClickSignIn}>
          <i className="bi bi-arrow-right-circle fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Sign In' })}
        </button>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </form>
  )
}
