import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getQna } from '../../../api/customer'
// import { getFAQ } from '../../../api/temp'
import { PageTitle } from '../../../_metronic/layout/core'

const FAQPage = ({ intl, tab, FAQ, handleClickTab }) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        {FAQ.length === 0 ? (
          <>{intl.formatMessage({ id: 'Registered FAQ does not exist.' })}</>
        ) : (
          <></>
        )}
        <div className="accordion" id="accordionExample">
          {FAQ.map((item, idx) => {
            return (
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={`accordion-button ${
                      tab !== item.tabNo ? 'collapsed' : ''
                    }`}
                    type="button"
                    onClick={() => handleClickTab(item.tabNo)}
                  >
                    {item.title}
                  </button>
                </h2>
                {tab === item.tabNo ? (
                  <>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body">{item.content}</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </>
)

const FAQWrapper: FC = () => {
  // hooks
  const intl = useIntl()

  // state
  const [tab, setTab] = useState(0)
  const [FAQ, setFAQ] = useState([])

  // handler
  const handleClickTab = (tabNo: number) => {
    if (tab === tabNo) {
      setTab(0)
    } else {
      setTab(tabNo)
    }
  }

  // init data
  const initData = async () => {
    const { data: response } = await getQna()
    setFAQ(response.data)
  }

  // lifecycle
  useEffect(() => {
    // init data
    initData()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'Frequently Asked Questions' })}
      </PageTitle>

      <FAQPage
        intl={intl}
        tab={tab}
        FAQ={FAQ}
        handleClickTab={handleClickTab}
      />
    </>
  )
}

export { FAQWrapper }
