import { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Theme, toast } from 'react-toastify'
import { useAuth } from '../../modules/auth/core/Auth'
import { useThemeMode } from '../../../_metronic/partials'
import { getSignInHistory } from '../../../api/temp'
import {
  changePassword,
  account as accountAPI,
  signHistory,
  account,
  sendSMS,
  verifySMS,
  signExit,
  qrCodeAPI,
  activeOTPAPI
} from '../../../api/user'
import moment from 'moment'
import Pagination from 'react-js-pagination'

const AccountSettingsPage = ({
  intl,
  account,
  phone,
  pagination,
  isDisabled,
  authNumber,
  authWait,
  password,
  confirmPassword,
  qrCode,
  showChangePasswordModal,
  showSignInHistoryModal,
  showDeleteUserModal,
  showSMSModal,
  showOTPModal,
  handleOpenChangePasswordModal,
  handleCloseChangePasswordModal,
  handleOpenSignInHistoryModal,
  handleCloseSignInHistoryModal,
  handleOpenDeleteUserModal,
  handleCloseDeleteUserModal,
  handleChangeOldPassword,
  handleChangeNewPassword,
  handleChangeConfirmNewPassword,
  handleClickChangePassword,
  handleChangePage,
  handleOpenSMSModal,
  handleCloseSMSModal,
  handleChangeAuthNumber,
  handleChangePhone,
  handleClickSendSMS,
  handleClickVerifySMS,
  handleClickDeleteUser,
  handleChangePassword,
  handleChangeConfirmPassword,
  handleOpenOTPModal,
  handleCloseOTPModal,
  handleClickActiveOTP,
  handleChangeToken
}) => (
  <>
    <div>{intl.formatMessage({ id: 'Security Rating' })}</div>

    <div className="row mt-3">
      <div className="col-12 col-md-6 mt-2">
        <div className="card card-custom">
          <div className="card-body">
            <div className="c-tac">
              <i className="bi bi-file-person-fill security-grade-icon"></i>
            </div>
            <div className="security-grade-step c-tac mt-3">
              {intl.formatMessage({ id: 'Security' })} 1{' '}
              {intl.formatMessage({ id: 'Step' })}
            </div>
            <div className="security-grade-title c-tac mt-5">
              {intl.formatMessage({ id: 'Enter member information' })}
            </div>
            <div className="security-grade-content c-tac mt-5">
              {intl.formatMessage({
                id: 'Please enter the required information (email, password)'
              })}
            </div>
            <div className="c-tac">
              <i className="bi bi-check security-grade-check"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-6 mt-2">
        <div className="card card-custom">
          <div className="card-body">
            <div className="c-tac">
              <i className="bi bi-envelope security-grade-icon"></i>
            </div>
            <div className="security-grade-step c-tac mt-3">
              {intl.formatMessage({ id: 'Security' })} 2{' '}
              {intl.formatMessage({ id: 'Step' })}
            </div>
            <div className="security-grade-title c-tac mt-5">
              {intl.formatMessage({ id: 'Email Authentication' })}
            </div>
            <div className="security-grade-content c-tac mt-5">
              {intl.formatMessage({
                id: 'Please complete the email authentication.'
              })}
            </div>
            <div className="c-tac">
              <i className="bi bi-check security-grade-check"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tac">
          <span className="c-bold">{account.account}</span>{' '}
          {intl.formatMessage({ id: 'Security Rating' })}{' '}
          <span className="c-bold c-blue">
            {account.isSmsVerify === 1 ? <>3</> : <>2</>}{' '}
            {intl.formatMessage({ id: 'Step' })}
          </span>{' '}
        </div>
      </div>
    </div>

    <div className="mt-3">
      ※{' '}
      {intl.formatMessage({
        id: 'Membership services are limited by security level, so the higher the level, the more various services you can use.'
      })}
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tar">
          <button
            className="btn btn-info mr-3"
            onClick={handleOpenSignInHistoryModal}
          >
            <i className="bi bi-clock fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Sign In History' })}
          </button>
          <button
            className="btn btn-primary mr-3"
            onClick={handleOpenChangePasswordModal}
          >
            <i className="bi bi-key fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Change Password' })}
          </button>
          <button
            className="btn btn-danger mr-3"
            onClick={handleOpenDeleteUserModal}
          >
            <i className="bi bi-clipboard2-x fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Membership Withdrawal' })}
          </button>

          {account.isActiveOtp === 1 ? (
            <></>
          ) : (
            <>
              <button className="btn btn-success" onClick={handleOpenOTPModal}>
                <i className="bi bi-check fs-4 me-2"></i>
                {intl.formatMessage({ id: 'OTP Activate' })}
              </button>
            </>
          )}
        </div>
      </div>
    </div>

    <Modal show={showSignInHistoryModal} onHide={handleCloseSignInHistoryModal}>
      <div className="close-area">
        <i
          className="close-icon bi bi-x-lg"
          onClick={handleCloseSignInHistoryModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Sign In History' })}
      </h1>
      <div className="mb-5">
        {pagination.data.length === 0 ? (
          <>
            <div className="c-tac mt-5">
              {intl.formatMessage({ id: 'Sign In history does not exist.' })}
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-rounded table-striped border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'State' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'IP Address' })}
                    </th>
                    <th scope="col">{intl.formatMessage({ id: 'Country' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'SignIn date and time' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <th>{item.id}</th>
                        <td>{item.loginStatus}</td>
                        <td>{item.loginIp}</td>
                        <td>{item.loginIpCountry}</td>
                        <td>
                          {item.createdAt
                            ? moment(item.createdAt).format('YYYY-MM-DD')
                            : ''}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-5">
              <Pagination
                activePage={pagination.activePage}
                itemsCountPerPage={pagination.itemsCountPerPage}
                totalItemsCount={pagination.totalItemsCount}
                pageRangeDisplayed={pagination.pageRangeDisplayed}
                onChange={handleChangePage}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          </>
        )}
      </div>
    </Modal>

    <Modal show={showDeleteUserModal} onHide={handleCloseDeleteUserModal}>
      <div className="close-area">
        <i
          className="close-icon bi bi-x-lg"
          onClick={handleCloseDeleteUserModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Membership Withdrawal' })}
      </h1>
      <div className="mb-5">
        <div className="form-group mt-5">
          <label htmlFor="password">
            {intl.formatMessage({ id: 'Password' })}
          </label>
          <input
            type="password"
            className="form-control mt-3"
            id="password"
            onChange={handleChangePassword}
            value={password}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="confirmPassword">
            {intl.formatMessage({ id: 'Confirm Password' })}
          </label>
          <input
            type="password"
            className="form-control mt-3"
            id="confirmPassword"
            onChange={handleChangeConfirmPassword}
            value={confirmPassword}
          />
        </div>
        <div className="mt-5">
          <div className="mt-3">
            ※{' '}
            {intl.formatMessage({
              id: 'Please note that when you withdraw, your personal information will be deleted and cannot be recovered.'
            })}
          </div>
          <div className="mt-3">
            ※{' '}
            {intl.formatMessage({
              id: 'If you withdraw from the membership, the ID will be permanently deleted, so you cannot rejoin.'
            })}
          </div>
          <div className="mt-3">
            ※{' '}
            {intl.formatMessage({
              id: 'Your member information (name, ID, e-mail address, contact, etc.) will be kept for one year after withdrawing from the membership to prevent re-subscription of defective users, and to cooperate in rights infringement disputes and investigations.'
            })}
          </div>
        </div>
      </div>
      <div
        className="c-tac mt-5 mb-5"
        style={{ paddingLeft: '30px', paddingRight: '30px' }}
      >
        <button
          className="btn btn-danger custom-w100p"
          onClick={handleClickDeleteUser}
          disabled={isDisabled}
        >
          <i className="bi bi-clipboard2-x fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Membership Withdrawal' })}
        </button>
      </div>
    </Modal>

    <Modal
      show={showChangePasswordModal}
      onHide={handleCloseChangePasswordModal}
    >
      <div className="close-area">
        <i
          className="close-icon bi bi-x-lg"
          onClick={handleCloseChangePasswordModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Change Password' })}
      </h1>
      <div className="mb-5">
        <div className="form-group mt-5">
          <label htmlFor="oldPassowrd">
            {intl.formatMessage({ id: 'Old password' })}
          </label>
          <input
            type="password"
            className="form-control mt-3"
            id="oldPassowrd"
            onChange={handleChangeOldPassword}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="newPassword">
            {intl.formatMessage({ id: 'New password' })}
          </label>
          <input
            type="password"
            className="form-control mt-3"
            id="newPassword"
            onChange={handleChangeNewPassword}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="confirmNewPassword">
            {intl.formatMessage({ id: 'Confirm new password' })}
          </label>
          <input
            type="password"
            className="form-control mt-3"
            id="confirmNewPassword"
            onChange={handleChangeConfirmNewPassword}
          />
        </div>
      </div>
      <div
        className="c-tac mt-5 mb-5"
        style={{ paddingLeft: '30px', paddingRight: '30px' }}
      >
        <button
          className="btn btn-primary custom-w100p"
          onClick={handleClickChangePassword}
          disabled={isDisabled}
        >
          <i className="bi bi-key fs-4 me-2"></i>
          {intl.formatMessage({ id: 'Change Password' })}
        </button>
      </div>
    </Modal>

    <Modal show={showSMSModal} onHide={handleCloseSMSModal}>
      <div className="close-area">
        <i className="close-icon bi bi-x-lg" onClick={handleCloseSMSModal}></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'SMS Authentication' })}
      </h1>
      <div className="mb-5">
        <div className="form-group mt-5">
          <label htmlFor="phone">{intl.formatMessage({ id: 'Phone' })}</label>
          <input
            type="text"
            className="form-control mt-3"
            id="phone"
            onChange={handleChangePhone}
            value={phone}
            disabled={authWait}
          />
        </div>
        <div className="form-group mt-5">
          <label htmlFor="authNumber">
            {intl.formatMessage({ id: 'Authentication number' })}
          </label>
          <input
            type="text"
            className="form-control mt-3"
            id="authNumber"
            onChange={handleChangeAuthNumber}
            value={authNumber}
            disabled={!authWait}
          />
        </div>
      </div>
      <div
        className="c-tac mt-5 mb-5"
        style={{ paddingLeft: '30px', paddingRight: '30px' }}
      >
        {!authWait ? (
          <>
            <button
              className="btn btn-success custom-w100p"
              onClick={handleClickSendSMS}
              disabled={isDisabled}
            >
              <i className="bi bi-clipboard2-x fs-4 me-2"></i>
              {intl.formatMessage({ id: 'Send authentication number' })}
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-success custom-w100p"
              onClick={handleClickVerifySMS}
              disabled={isDisabled}
            >
              <i className="bi bi-clipboard2-x fs-4 me-2"></i>
              {intl.formatMessage({
                id: 'Verifying the authentication number'
              })}
            </button>
          </>
        )}
      </div>
    </Modal>

    <Modal show={showOTPModal} onHide={handleCloseOTPModal}>
      <div className="close-area">
        <i className="close-icon bi bi-x-lg" onClick={handleCloseOTPModal}></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'OTP Activate' })}
      </h1>

      <div className="card card-custom mt-5">
        <div className="card-body">
          <div>
            <div className="c-tac">
              <img className="qrcode-img" src={qrCode} alt="qrcode" />
            </div>

            <div className="mt-5">
              <input
                type="test"
                className="form-control custom-tac"
                value={account.secret}
                disabled
              />
            </div>
            <div className="form-group mt-5">
              <label htmlFor="token">
                {intl.formatMessage({ id: 'OTP code' })}
              </label>
              <input
                type="password"
                className="form-control mt-3"
                id="token"
                onChange={handleChangeToken}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="c-tac mt-5 mb-5"
        style={{ paddingLeft: '30px', paddingRight: '30px' }}
      >
        <button
          className="btn btn-success custom-w100p"
          onClick={handleClickActiveOTP}
          disabled={isDisabled}
        >
          <i className="bi bi-check fs-4 me-2"></i>
          {intl.formatMessage({ id: 'OTP Activate' })}
        </button>
      </div>
    </Modal>
  </>
)

const AccountSettingsWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const { logout } = useAuth()
  const { mode } = useThemeMode()

  // state - account information
  const [account, setAccount] = useState({})

  // state - change password
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  // state - delete user
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // state - sms auth
  const [authNumber, setAuthNumber] = useState('')
  const [phone, setPhone] = useState('')
  const [authWait, setAuthWait] = useState(false)
  const [messageId, setMessageId] = useState('')

  // state - common
  const [isDisabled, setIsDisabled] = useState(false)
  const [token, setToken] = useState('')
  const [qrCode, setQrCode] = useState('')

  // state - modal
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showSignInHistoryModal, setShowSignInHistoryModal] = useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false)
  const [showSMSModal, setShowSMSModal] = useState(false)
  const [showOTPModal, setShowOTPModal] = useState(false)

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber
      }
      const { data: response } = await signHistory(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.historyTotalCount,
          data: response.data.loginHistoryList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - open change password modal
  const handleOpenChangePasswordModal = () => {
    setShowChangePasswordModal(true)
  }

  // handler - close change password modal
  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false)
  }

  // handler - open SMS modal
  const handleOpenSMSModal = () => {
    setAuthWait(false)
    setAuthNumber('')
    setPhone('')
    setShowSMSModal(true)
  }

  // handler - close SMS modal
  const handleCloseSMSModal = () => {
    setShowSMSModal(false)
  }

  // handler - open sign in history modal
  const handleOpenSignInHistoryModal = () => {
    setShowSignInHistoryModal(true)
  }

  // handler - close sign in history modal
  const handleCloseSignInHistoryModal = () => {
    setShowSignInHistoryModal(false)
  }

  // handler - open delete user modal
  const handleOpenDeleteUserModal = () => {
    setShowDeleteUserModal(true)
  }

  // handler - close delete user modal
  const handleCloseDeleteUserModal = () => {
    setShowDeleteUserModal(false)
  }

  // handler - open otp modal
  const handleOpenOTPModal = () => {
    setShowOTPModal(true)
  }

  // handler - close otp modal
  const handleCloseOTPModal = () => {
    setShowOTPModal(false)
    setToken('')
  }

  // handler - change token
  const handleChangeToken = (e) => {
    setToken(e.target.value)
  }

  // handler - change password
  const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }

  // handler - change confirm password
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }

  // handler - change old password
  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value)
  }

  // handler - change new password
  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  // handler - change confirm new password
  const handleChangeConfirmNewPassword = (e) => {
    setConfirmNewPassword(e.target.value)
  }

  // handler - phone
  const handleChangePhone = (e) => {
    setPhone(e.target.value)
  }

  // handler - auth number
  const handleChangeAuthNumber = (e) => {
    setAuthNumber(e.target.value)
  }

  // handler - click send SMS
  const handleClickSendSMS = async (e) => {
    setIsDisabled(true)
    try {
      const params = {
        phoneNumber: `+82${phone}`
      }
      const { data: response } = await sendSMS(params)
      if (response.statusCode === 200) {
        setAuthWait(true)
        setMessageId(response.data)
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler - click Verify SMS
  const handleClickVerifySMS = async (e) => {
    setIsDisabled(true)
    try {
      const params = {
        verifyCode: authNumber,
        messageId
      }
      const { data: response } = await verifySMS(params)
      if (response.statusCode === 200) {
        handleCloseSMSModal()
        initData()
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler - click change password
  const handleClickChangePassword = async () => {
    setIsDisabled(true)
    try {
      const params = {
        oldPassword,
        newPassword,
        confirmNewPassword
      }
      const { data: response } = await changePassword(params)
      if (response.statusCode === 200) {
        alert(intl.formatMessage({ id: response.message }))
        setIsDisabled(false)
        logout()
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler - click delete user
  const handleClickDeleteUser = async () => {
    setIsDisabled(true)
    try {
      const params = {
        password,
        confirmPassword
      }
      const { data: response } = await signExit(params)
      if (response.statusCode === 200) {
        alert(intl.formatMessage({ id: response.message }))
        setIsDisabled(false)
        logout()
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler - click activate OTP button
  const handleClickActiveOTP = async () => {
    setIsDisabled(true)
    try {
      const params = {
        token
      }
      const { data: response } = await activeOTPAPI(params)
      if (response.statusCode === 200) {
        handleCloseOTPModal()
        setAccount({ ...account, isActiveOtp: 1 })
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        setToken('')
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // init data
  const initData = async () => {
    const { data } = await accountAPI()
    if (data.statusCode === 200) {
      setAccount(data.data)
    }

    await qrCodeAPI().then((data) => {
      if (data.data.statusCode === 200) {
        setQrCode(data.data.data)
      }
    })

    // set table
    try {
      const params = {
        page: pagination.activePage
      }
      const { data: response } = await signHistory(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.historyTotalCount,
          data: response.data.loginHistoryList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.ACCOUNT.SETTINGS' })}
      </PageTitle>

      <AccountSettingsPage
        intl={intl}
        account={account}
        phone={phone}
        pagination={pagination}
        isDisabled={isDisabled}
        authNumber={authNumber}
        authWait={authWait}
        password={password}
        confirmPassword={confirmPassword}
        qrCode={qrCode}
        showChangePasswordModal={showChangePasswordModal}
        showSignInHistoryModal={showSignInHistoryModal}
        showDeleteUserModal={showDeleteUserModal}
        showSMSModal={showSMSModal}
        showOTPModal={showOTPModal}
        handleOpenChangePasswordModal={handleOpenChangePasswordModal}
        handleCloseChangePasswordModal={handleCloseChangePasswordModal}
        handleOpenSignInHistoryModal={handleOpenSignInHistoryModal}
        handleCloseSignInHistoryModal={handleCloseSignInHistoryModal}
        handleOpenDeleteUserModal={handleOpenDeleteUserModal}
        handleCloseDeleteUserModal={handleCloseDeleteUserModal}
        handleChangeOldPassword={handleChangeOldPassword}
        handleChangeNewPassword={handleChangeNewPassword}
        handleChangeConfirmNewPassword={handleChangeConfirmNewPassword}
        handleClickChangePassword={handleClickChangePassword}
        handleChangePage={handleChangePage}
        handleOpenSMSModal={handleOpenSMSModal}
        handleCloseSMSModal={handleCloseSMSModal}
        handleChangeAuthNumber={handleChangeAuthNumber}
        handleChangePhone={handleChangePhone}
        handleClickSendSMS={handleClickSendSMS}
        handleClickVerifySMS={handleClickVerifySMS}
        handleClickDeleteUser={handleClickDeleteUser}
        handleChangePassword={handleChangePassword}
        handleChangeConfirmPassword={handleChangeConfirmPassword}
        handleOpenOTPModal={handleOpenOTPModal}
        handleCloseOTPModal={handleCloseOTPModal}
        handleClickActiveOTP={handleClickActiveOTP}
        handleChangeToken={handleChangeToken}
      />
    </>
  )
}

export { AccountSettingsWrapper }
