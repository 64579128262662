import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import Pagination from 'react-js-pagination'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import DATE from '../../constants/date'
import ko from 'date-fns/locale/ko'
import ja from 'date-fns/locale/ja'
import en from 'date-fns/locale/en-US'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { Theme, toast } from 'react-toastify'
import { useThemeMode } from '../../../_metronic/partials'
import {
  userListPagination,
  updatePassword as updatePasswordAPI,
  updateUser as updateUserAPI
} from '../../../api/admin'
import { Modal } from 'react-bootstrap'

const UserManagementPage = ({
  intl,
  isDisabled,
  selectRow,
  search,
  pagination,
  selectBoxAdmin,
  selectBoxDeveloper,
  selectBoxEmail,
  selectBoxSMS,
  selectBoxOTP,
  showModal,
  showUserModal,
  showPasswordModal,
  updateUserInfo,
  updatePassword,
  handleChangePage,
  handleClickSearch,
  handleChangeSearch,
  handleOpenModal,
  handleCloseModal,
  handleOpenUserModal,
  handleCloseUserModal,
  handleOpenPasswordModal,
  handleClosePasswordModal,
  handleChangeUserInfo,
  handleChangePassword,
  handleClickUpdateUser,
  handleClickUpdatePassword
}) => (
  <>
    <div className="card card-custom">
      <div className="card-body">
        <div className="row">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sample1">
                {intl.formatMessage({ id: 'ID' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="id"
                value={search.id}
                onChange={(e) => handleChangeSearch('id', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="account">
                {intl.formatMessage({ id: 'Account' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="account"
                value={search.account}
                onChange={(e) => handleChangeSearch('account', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="username">
                {intl.formatMessage({ id: 'Username' })}
              </label>
              <input
                type="text"
                className="form-control mt-3"
                id="username"
                value={search.username}
                onChange={(e) => handleChangeSearch('username', e)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="createdAt">
                {intl.formatMessage({ id: 'Date and time of creation' })}
              </label>
              <DatePicker
                className="form-control mt-3"
                dateFormat="yyyy-MM-dd"
                selected={search.createdAt}
                onChange={(date: Date) => handleChangeSearch('createdAt', date)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="isAdmin">
                {intl.formatMessage({ id: 'Administrator authority' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('isAdmin', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxAdmin.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="email">
                {intl.formatMessage({ id: 'Email Authentication' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('is_email_verify', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxEmail.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sms">
                {intl.formatMessage({ id: 'SMS Authentication' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('is_sms_verify', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxSMS.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="form-group">
              <label htmlFor="sms">
                {intl.formatMessage({ id: 'OTP Authentication' })}
              </label>
              <select
                className="form-select mt-3"
                onChange={(e) => handleChangeSearch('isActiveOtp', e)}
              >
                <option value="">
                  ----- {intl.formatMessage({ id: 'Select Item' })} -----
                </option>
                {selectBoxSMS.map((item, idx) => (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body">
        <div className="c-tar">
          <button className="btn btn-light-primary" onClick={handleClickSearch}>
            <i className="bi bi-search fs-4 me-2"></i>
            {intl.formatMessage({ id: 'Search' })}
          </button>
        </div>
      </div>
    </div>

    <div className="card card-custom mt-5">
      <div className="card-body pd-0">
        {pagination.data.length === 0 ? (
          <>
            <div className="c-tac">
              {intl.formatMessage({
                id: 'Data does not exist.'
              })}
            </div>
          </>
        ) : (
          <>
            <div className="table-responsive">
              <table className="table table-rounded table-striped border gy-7 gs-7">
                <thead>
                  <tr>
                    <th scope="col">{intl.formatMessage({ id: 'ID' })}</th>
                    <th scope="col">{intl.formatMessage({ id: 'Account' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Username' })}
                    </th>
                    <th scope="col">{intl.formatMessage({ id: 'Phone' })}</th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Wallet Address' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Administrator authority' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Email Authentication' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'SMS Authentication' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'OTP Authentication' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of creation' })}
                    </th>
                    <th scope="col">
                      {intl.formatMessage({ id: 'Date and time of update' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pagination.data.map((item, idx) => {
                    return (
                      <tr
                        key={idx}
                        className="c-cp"
                        onClick={() => handleOpenModal(item)}
                      >
                        <th>{item.id}</th>
                        <th>{item.account}</th>
                        <th>{item.username}</th>
                        <th>{item.phone}</th>
                        <th>{item.walletAddress}</th>
                        <th>
                          {item.admin === 1 ? (
                            <>
                              <span className="badge badge-success">O</span>
                            </>
                          ) : (
                            <>
                              <span className="badge badge-danger">X</span>
                            </>
                          )}
                        </th>
                        <th>
                          {item.emailVerify === 1 ? (
                            <>
                              <span className="badge badge-success">O</span>
                            </>
                          ) : (
                            <>
                              <span className="badge badge-danger">X</span>
                            </>
                          )}
                        </th>
                        <th>
                          {item.smsVerify === 1 ? (
                            <>
                              <span className="badge badge-success">O</span>
                            </>
                          ) : (
                            <>
                              <span className="badge badge-danger">X</span>
                            </>
                          )}
                        </th>
                        <th>
                          {item.isActiveOTP === 1 ? (
                            <>
                              <span className="badge badge-success">O</span>
                            </>
                          ) : (
                            <>
                              <span className="badge badge-danger">X</span>
                            </>
                          )}
                        </th>
                        <th>
                          {item.createdAt
                            ? moment(item.createdAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                        <th>
                          {item.updatedAt
                            ? moment(item.updatedAt).format(
                                'YYYY-MM-DD HH:mm:ss'
                              )
                            : ''}
                        </th>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>

    <div className="mt-5">
      <Pagination
        activePage={pagination.activePage}
        itemsCountPerPage={pagination.itemsCountPerPage}
        totalItemsCount={pagination.totalItemsCount}
        pageRangeDisplayed={pagination.pageRangeDisplayed}
        onChange={handleChangePage}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>

    <Modal show={showModal} onHide={handleCloseModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Change user information' })}
      </h1>
      <div className="form-group mt-5">
        <label htmlFor="account">{intl.formatMessage({ id: 'Account' })}</label>
        <input
          type="test"
          className="form-control mt-3"
          id="account"
          value={selectRow?.account}
          disabled
        />
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-primary c-w100p"
            onClick={handleOpenUserModal}
          >
            <i className="bi bi-arrow-left-circle mr-1"></i>
            {intl.formatMessage({ id: 'Change user information' })}
          </button>
          <button
            className="btn btn-primary c-w100p mt-5"
            onClick={handleOpenPasswordModal}
          >
            <i className="bi bi-arrow-left-circle mr-1"></i>
            {intl.formatMessage({ id: 'Change Password' })}
          </button>
        </div>
      </div>
    </Modal>

    <Modal show={showUserModal} onHide={handleCloseUserModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleCloseUserModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Change user information' })}
      </h1>
      <div className="form-group mt-5">
        <label htmlFor="username">
          {intl.formatMessage({ id: 'Username' })}
        </label>
        <input
          type="text"
          className="form-control mt-3"
          id="username"
          value={updateUserInfo?.username}
          onChange={(e) => handleChangeUserInfo('username', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="phone">{intl.formatMessage({ id: 'Phone' })}</label>
        <input
          type="text"
          className="form-control mt-3"
          id="phone"
          value={updateUserInfo?.phone}
          onChange={(e) => handleChangeUserInfo('phone', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'Administrator authority' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('admin', e)}
        >
          {selectBoxAdmin.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isAdmin === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'Email Authentication' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('email', e)}
        >
          {selectBoxEmail.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isEmail === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'SMS Authentication' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('sms', e)}
        >
          {selectBoxSMS.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isSMS === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mt-5">
        <label htmlFor="sms">
          {intl.formatMessage({ id: 'OTP Authentication' })}
        </label>
        <select
          className="form-select mt-3"
          onChange={(e) => handleChangeUserInfo('otp', e)}
        >
          {selectBoxOTP.map((item, idx) => (
            <option
              key={idx}
              value={item.value}
              selected={updateUserInfo?.isOTP === item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-success c-w100p"
            onClick={handleClickUpdateUser}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i>{' '}
            {intl.formatMessage({ id: 'BUTTON.Update' })}
          </button>
        </div>
      </div>
    </Modal>

    <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
      <div className="modal-close">
        <i
          className="modal-close-icon bi bi-x-lg"
          onClick={handleClosePasswordModal}
        ></i>
      </div>
      <h1 className="c-tac mt-5 mb-5">
        {intl.formatMessage({ id: 'Change Password' })}
      </h1>
      <div className="form-group mt-5">
        <label htmlFor="newPassword">
          {intl.formatMessage({ id: 'New password' })}
        </label>
        <input
          type="text"
          className="form-control mt-3"
          id="newPassword"
          value={updatePassword?.newPassword}
          onChange={(e) => handleChangePassword('newPassword', e)}
        />
      </div>
      <div className="form-group mt-5">
        <label htmlFor="confirmNewPassword">
          {intl.formatMessage({ id: 'Confirm new password' })}
        </label>
        <input
          type="text"
          className="form-control mt-3"
          id="confirmNewPassword"
          value={updatePassword?.confirmNewPassword}
          onChange={(e) => handleChangePassword('confirmNewPassword', e)}
        />
      </div>
      <div className="mt-10">
        <div>
          <button
            className="btn btn-success c-w100p"
            onClick={handleClickUpdatePassword}
            disabled={isDisabled}
          >
            <i className="bi bi-check"></i>{' '}
            {intl.formatMessage({ id: 'BUTTON.Update' })}
          </button>
        </div>
      </div>
    </Modal>
  </>
)

const UserManagementWrapper: FC = () => {
  // hooks
  const intl = useIntl()
  const locale = useLang()
  const { mode } = useThemeMode()

  // state - pagination
  const [pagination, setPagination] = useState({
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    totalItemsCount: 0,
    activePage: 1,
    data: []
  })

  // state - search
  const [search, setSearch] = useState({
    id: '',
    account: '',
    username: '',
    isAdmin: '',
    isDeveloper: '',
    isEmailVerify: '',
    isSMSVerify: '',
    isActiveOtp: '',
    createdAt: ''
  })

  // state
  const [showModal, setShowModal] = useState(false)
  const [showUserModal, setShowUserModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [selectRow, setSelectRow] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)

  const [updateUserInfo, setUpdateUserInfo] = useState({
    userId: 0,
    username: '',
    phone: '',
    isAdmin: '',
    isEmail: '',
    isSMS: '',
    isOTP: ''
  })

  const [updatePassword, setUpdatePassword] = useState({
    userId: 0,
    newPassword: '',
    confirmNewPassword: ''
  })

  // handler
  const handleOpenModal = (data) => {
    setUpdateUserInfo({
      userId: data.id,
      username: data.username,
      phone: data.phone,
      isAdmin: data.admin,
      isEmail: data.emailVerify,
      isSMS: data.smsVerify,
      isOTP: data.isActiveOTP
    })
    setUpdatePassword({
      userId: data.id,
      newPassword: '',
      confirmNewPassword: ''
    })
    setSelectRow(data)
    setShowModal(true)
  }

  // handler
  const handleCloseModal = () => {
    setShowModal(false)
  }

  // handler
  const handleOpenUserModal = () => {
    setShowUserModal(true)
  }

  // handler
  const handleCloseUserModal = () => {
    setShowUserModal(false)
  }

  // handler
  const handleOpenPasswordModal = () => {
    setShowPasswordModal(true)
  }

  // handler
  const handleClosePasswordModal = () => {
    setShowPasswordModal(false)
  }

  // state - admin
  const [selectBoxAdmin, setSelectBoxAdmin] = useState([])

  // state - developer
  const [selectBoxDeveloper, setSelectBoxDeveloper] = useState([])

  // state - email
  const [selectBoxEmail, setSelectBoxEmail] = useState([])

  // state - sms
  const [selectBoxSMS, setSelectBoxSMS] = useState([])

  // state - otp
  const [selectBoxOTP, setSelectBoxOTP] = useState([])

  // handler - change page
  const handleChangePage = async (pageNumber: number) => {
    const createdAt = search.createdAt
      ? moment(search.createdAt).format(DATE.ONLY_DATE)
      : ''

    // change paging
    setPagination({ ...pagination, activePage: pageNumber })

    // set table
    try {
      const params = {
        page: pageNumber,
        id: search.id,
        account: search.account,
        username: search.username,
        isAdmin: search.isAdmin,
        isDeveloper: search.isDeveloper,
        isEmailVerify: search.isEmailVerify,
        isSMSVerify: search.isSMSVerify,
        isActiveOtp: search.isActiveOtp,
        createdAt: createdAt
      }
      const { data: response } = await userListPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - click search
  const handleClickSearch = async () => {
    const createdAt = search.createdAt
      ? moment(search.createdAt).format(DATE.ONLY_DATE)
      : ''

    // set table
    try {
      const params = {
        page: 1,
        id: search.id,
        account: search.account,
        username: search.username,
        isAdmin: search.isAdmin,
        isDeveloper: search.isDeveloper,
        isEmailVerify: search.isEmailVerify,
        isSMSVerify: search.isSMSVerify,
        isActiveOtp: search.isActiveOtp,
        createdAt: createdAt
      }
      const { data: response } = await userListPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // handler - change search
  const handleChangeSearch = (type: string, data: any) => {
    if (type === 'id') {
      setSearch({ ...search, id: data.target.value })
    } else if (type === 'account') {
      setSearch({ ...search, account: data.target.value })
    } else if (type === 'username') {
      setSearch({ ...search, username: data.target.value })
    } else if (type === 'isAdmin') {
      setSearch({ ...search, isAdmin: data.target.value })
    } else if (type === 'isDeveloper') {
      setSearch({ ...search, isDeveloper: data.target.value })
    } else if (type === 'is_email_verify') {
      setSearch({ ...search, isEmailVerify: data.target.value })
    } else if (type === 'is_sms_verify') {
      setSearch({ ...search, isSMSVerify: data.target.value })
    } else if (type === 'isActiveOtp') {
      setSearch({ ...search, isActiveOtp: data.target.value })
    } else if (type === 'createdAt') {
      setSearch({ ...search, createdAt: data })
    }
  }

  // handler
  const handleChangeUserInfo = (type: string, data: any) => {
    if (type === 'username') {
      setUpdateUserInfo({ ...updateUserInfo, username: data.target.value })
    } else if (type === 'phone') {
      setUpdateUserInfo({ ...updateUserInfo, phone: data.target.value })
    } else if (type === 'admin') {
      setUpdateUserInfo({ ...updateUserInfo, isAdmin: data.target.value })
    } else if (type === 'email') {
      setUpdateUserInfo({ ...updateUserInfo, isEmail: data.target.value })
    } else if (type === 'sms') {
      setUpdateUserInfo({ ...updateUserInfo, isSMS: data.target.value })
    } else if (type === 'otp') {
      setUpdateUserInfo({ ...updateUserInfo, isOTP: data.target.value })
    }
  }

  // handler
  const handleChangePassword = (type: string, data: any) => {
    if (type === 'newPassword') {
      setUpdatePassword({ ...updatePassword, newPassword: data.target.value })
    } else if (type === 'confirmNewPassword') {
      setUpdatePassword({
        ...updatePassword,
        confirmNewPassword: data.target.value
      })
    }
  }

  // handler
  const handleClickUpdateUser = async () => {
    setIsDisabled(true)
    try {
      const params = updateUserInfo
      const { data: response } = await updateUserAPI(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        handleCloseUserModal()
        handleCloseModal()
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // handler
  const handleClickUpdatePassword = async () => {
    setIsDisabled(true)
    try {
      const params = updatePassword
      const { data: response } = await updatePasswordAPI(params)
      if (response.statusCode === 200) {
        toast.info(intl.formatMessage({ id: response.message }), {
          theme: mode as Theme
        })
        handleClosePasswordModal()
        handleCloseModal()
        initData()
        setIsDisabled(false)
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
      setIsDisabled(false)
    }
  }

  // init data
  const initData = async () => {
    // set select box data
    const isAdminData = [
      { label: intl.formatMessage({ id: 'User' }), value: 0 },
      { label: intl.formatMessage({ id: 'Admin' }), value: 1 }
    ]

    const isDeveloperData = [
      { label: intl.formatMessage({ id: 'User' }), value: 0 },
      { label: intl.formatMessage({ id: 'Developer' }), value: 1 }
    ]

    const data = [
      { label: intl.formatMessage({ id: 'Certified' }), value: 1 },
      { label: intl.formatMessage({ id: 'Uncertified' }), value: 0 }
    ]

    setSelectBoxAdmin(isAdminData)
    setSelectBoxDeveloper(isDeveloperData)
    setSelectBoxEmail(data)
    setSelectBoxSMS(data)
    setSelectBoxOTP(data)

    // set table
    try {
      const createdAt = search.createdAt
        ? moment(search.createdAt).format(DATE.ONLY_DATE)
        : ''

      const params = {
        page: pagination.activePage,
        id: search.id,
        account: search.account,
        username: search.username,
        isAdmin: search.isAdmin,
        isDeveloper: search.isDeveloper,
        isEmailVerify: search.isEmailVerify,
        isSMSVerify: search.isSMSVerify,
        isActiveOtp: search.isActiveOtp,
        createdAt: createdAt
      }
      const { data: response } = await userListPagination(params)
      if (response.statusCode === 200) {
        setPagination({
          ...pagination,
          totalItemsCount: response.data.userTotalCount,
          data: response.data.userList
        })
      }
    } catch (error) {
      toast.warning(intl.formatMessage({ id: error.response.data.message }), {
        theme: mode as Theme
      })
    }
  }

  // lifecycle
  useEffect(() => {
    initData()

    // init load lang
    registerLocale('ko', ko)
    registerLocale('ja', ja)
    registerLocale('en', en)

    // change datePicker Lang
    setDefaultLocale(locale)

    // unmounted
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: 'MENU.USER.USER_MANAGEMENT' })}
      </PageTitle>

      <UserManagementPage
        intl={intl}
        isDisabled={isDisabled}
        selectRow={selectRow}
        search={search}
        pagination={pagination}
        selectBoxAdmin={selectBoxAdmin}
        selectBoxDeveloper={selectBoxDeveloper}
        selectBoxEmail={selectBoxEmail}
        selectBoxSMS={selectBoxSMS}
        selectBoxOTP={selectBoxOTP}
        showModal={showModal}
        showUserModal={showUserModal}
        showPasswordModal={showPasswordModal}
        updateUserInfo={updateUserInfo}
        updatePassword={updatePassword}
        handleChangePage={handleChangePage}
        handleClickSearch={handleClickSearch}
        handleChangeSearch={handleChangeSearch}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        handleOpenUserModal={handleOpenUserModal}
        handleCloseUserModal={handleCloseUserModal}
        handleOpenPasswordModal={handleOpenPasswordModal}
        handleClosePasswordModal={handleClosePasswordModal}
        handleChangeUserInfo={handleChangeUserInfo}
        handleChangePassword={handleChangePassword}
        handleClickUpdateUser={handleClickUpdateUser}
        handleClickUpdatePassword={handleClickUpdatePassword}
      />
    </>
  )
}

export { UserManagementWrapper }
