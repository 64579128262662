import axios from 'axios'
import { CommonResponse } from '../../common/interface'

const rootURL = '/api/eth/'

export const getBalance = () => {
  const url = `${rootURL}balance`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getFee = () => {
  const url = `${rootURL}fee`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getKrwBalance = () => {
  const url = `${rootURL}balance/krw`
  const response = axios.get<CommonResponse>(url)
  return response
}

export const getHistory = (params: object) => {
  const url = `${rootURL}history`
  const response = axios.get<CommonResponse>(url, { params: params })
  return response
}

export const outerSend = (params: object) => {
  const url = `${rootURL}outer-send`
  const response = axios.post<CommonResponse>(url, params)
  return response
}
