/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { Theme, toast, ToastContainer } from 'react-toastify'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useLang } from '../../../../_metronic/i18n/Metronici18n'
import { useThemeMode } from '../../../../_metronic/partials'
import { resetPasswordMail } from '../../../../api/user'

export function ForgotPassword() {
  // hooks
  const intl = useIntl()
  const lang = useLang()
  const { mode } = useThemeMode()

  const navigate = useNavigate()

  // state
  const [loading, setLoading] = useState(false)

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'Wrong email format' }))
      .min(3, intl.formatMessage({ id: 'Minimum 3 symbols' }))
      .max(100, intl.formatMessage({ id: 'Maximum 100 symbols' }))
      .required(intl.formatMessage({ id: 'Email is required' }))
  })

  const initialValues = {
    email: '',
    password: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const params = {
          account: values.email,
          lang
        }
        const { data: response } = await resetPasswordMail(params)
        if (response.statusCode === 200) {
          toast.info(intl.formatMessage({ id: response.message }), {
            theme: mode as Theme
          })
          formik.resetForm()
        }
        setSubmitting(false)
        setLoading(false)
      } catch (error: any) {
        toast.warning(intl.formatMessage({ id: error.response.data.message }), {
          theme: 'dark'
        })
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  // lifecycle
  useEffect(() => {
    //
  }, [])

  return (
    <>
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        {/* begin::Heading */}
        <div className="mb-10 text-center">
          {/* begin::Title */}
          <h1 className="text-dark mb-3">
            {intl.formatMessage({ id: 'Find your password' })}
          </h1>
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        {formik.status ? (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <></>
        )}

        {/* begin::Form group */}
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">
            {intl.formatMessage({ id: 'Email' })}
          </label>
          <input
            placeholder="Email"
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email
              }
            )}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <span role="alert">{formik.errors.email}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_in_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: 'Find your password' })}
              </span>
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                {intl.formatMessage({ id: 'Please wait...' })}{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>

        <div>
          <button
            className="btn btn-light-dark w-100"
            onClick={() => {
              navigate('/auth/signIn')
            }}
          >
            {intl.formatMessage({ id: 'Move Sign In Page' })}
          </button>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </form>
    </>
  )
}
