import Lottie from 'react-lottie'
import { useIntl } from 'react-intl'

import * as emptyIconAnimation from '../lottie/empty-icon-animation.json'

const Empty = () => {
  // ** Hooks
  const intl = useIntl()

  return (
    <div>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: emptyIconAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        isClickToPauseDisabled
        height={250}
        width={250}
      />
      <div className="c-tac">
        {intl.formatMessage({
          id: 'Data does not exist.'
        })}
      </div>
    </div>
  )
}

export default Empty
